import React, { useState } from "react";
import { Layout, Menu, theme, Badge, Avatar, Input } from "antd";
import {
  DollarCircleFilled,
  FolderViewOutlined,
  MoneyCollectOutlined,
  ShoppingOutlined,
  StockOutlined,
  TransactionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Logo, APP_NAME } from "../../Config";
import { Link, useLocation } from "react-router-dom";

const { Sider } = Layout;
const { SubMenu } = Menu;

function Sidebar() {
  const {
    token: { colorBgBase },
  } = theme.useToken();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const user = {
    name: localStorage.getItem("FullName"),
    role: localStorage.getItem("Role"),
    image: "../../../assets/images/profile.jpg",
  };

  const handleSidebarToggle = () => {
    setCollapsed(!collapsed);
  };
  const menuData = [
    {
      key: "1",
      icon: <ShoppingOutlined />,
      label: "Manipulations",
      path: "/Manipulations",
      submenu: [
        {
          key: "1-1",
          label: "Add Product",
          path: "/dashboard/products", // Replace with the path for the respective page
        },
        {
          key: "1-2",
          label: "Product Category",
          path: "/dashboard/product-category", // Replace with the path for the respective page
        },
        {
          key: "1-3",
          label: "Contacts",
          path: "/dashboard/contacts", // Replace with the path for the respective page
        },
        {
          key: "1-4",
          label: "Expense Category",
          path: "/dashboard/expense-category", // Replace with the path for the respective page
        },
      ],
    },
    {
      key: "2",
      icon: <MoneyCollectOutlined />,
      label: "Transaction",
      path: "/Transaction",
      submenu: [
        {
          key: "2-1",
          label: "Purchase Invoice",
          path: "/dashboard/purchase-invoice", // Replace with the path for the respective page
        },
        {
          key: "2-2",
          label: "Purchase Return",
          path: "/dashboard/purchase-return", // Replace with the path for the respective page
        },
        {
          key: "2-3",
          label: "Sale Invoice",
          path: "/dashboard/sale-invoice", // Replace with the path for the respective page
        },
        {
          key: "2-4",
          label: "Sale Return",
          path: "/dashboard/sale-return", // Replace with the path for the respective page
        },
        {
          key: "2-5",
          label: "Add Expenses",
          path: "/dashboard/expenses/create", // Replace with the path for the respective page
        },
        {
          key: "2-6",
          label: "Opening Form",
          path: "/dashboard/opening-form", // Replace with the path for the respective page
        },
        {
          key: "2-7",
          label: "Payment Voucher",
          path: "/dashboard/payment-voucher", // Replace with the path for the respective page
        },
        {
          key: "2-8",
          label: "Receipt Voucher",
          path: "/dashboard/receipt-voucher", // Replace with the path for the respective page
        },
      ],
    },
    {
      key: "3",
      icon: <FolderViewOutlined />,
      label: "Views",
      path: "/Views",
      submenu: [
        {
          key: "1-1",
          icon: <TransactionOutlined />,
          label: "View Invoices",
          path: "/dashboard/view-invoices", // Replace with the path for the respective page
        },
        {
          key: "1-2",
          icon: <TransactionOutlined />,
          label: "Product Report",
          path: "/dashboard/product-report", // Replace with the path for the respective page
        },
        {
          key: "1-3",
          icon: <StockOutlined />,
          label: "Current Stock",
          path: "/dashboard/current-stock", // Replace with the path for the respective page
        },
        {
          key: "1-4",
          icon: <StockOutlined />,
          label: "Account Ledger",
          path: "/dashboard/account-ledger", // Replace with the path for the respective page
        },
        {
          key: "1-5",
          icon: <DollarCircleFilled />,
          label: "View Expenses",
          path: "/dashboard/expenses", // Replace with the path for the respective page
        },
        {
          key: "1-6",
          icon: <DollarCircleFilled />,
          label: "Income Report",
          path: "/dashboard/income-report", // Replace with the path for the respective page
        },
      ],
    },
  ];

  // Function to filter menu items and submenus based on the search query
  const filterMenuData = (data: any, query: any) => {
    return data
      .map((menuItem: any) => {
        if (menuItem.label.toLowerCase().includes(query.toLowerCase())) {
          return { ...menuItem };
        }

        if (menuItem.submenu) {
          const filteredSubmenu = filterMenuData(menuItem.submenu, query);
          if (filteredSubmenu.length > 0) {
            return { ...menuItem, submenu: filteredSubmenu };
          }
        }

        return null;
      })
      .filter(Boolean); // Filter out null values
  };

  // Get filtered menu data based on the search query
  const filteredMenuData = filterMenuData(menuData, searchQuery);

  // Function to render submenus recursively
  const renderSubMenuItems = (subMenuItems: any) => {
    return subMenuItems.map((item: any) => (
      <Menu.Item key={item.key}>
        <Link to={item.path}>{item.label}</Link>
      </Menu.Item>
    ));
  };

  const renderSubMenu = (menuItem: any) => {
    if (menuItem.submenu) {
      return (
        <SubMenu key={menuItem.key} icon={menuItem.icon} title={menuItem.label}>
          {renderSubMenuItems(menuItem.submenu)}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={menuItem.key} icon={menuItem.icon}>
        <Link to={menuItem.path}>{menuItem.label}</Link>
      </Menu.Item>
    );
  };

  // Function to render menu items and submenus
  const renderMenuItems = (menuItems: any) => {
    return menuItems.map((menuItem: any) => {
      return renderSubMenu(menuItem);
    });
  };

  const location = useLocation();

  return (
    <Sider
      //    ref={sidebarRef}
      breakpoint="md"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        // console.log(broken);
      }}
      onCollapse={handleSidebarToggle}
      collapsible
      collapsed={collapsed}
    >
      <div style={{ padding: "5px", textAlign: "left" }}>
        <Link
          to="/dashboard"
          style={{ textDecoration: "none", color: "white" }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <Avatar size={45} src={Logo} icon={<UserOutlined />} />
            <h4 style={{ margin: "1px 8px 1px 3px", color: "white" }}>
              {APP_NAME}
            </h4>
          </span>
        </Link>
      </div>

      <div style={{ padding: "5px", textAlign: "center" }}>
        <Link
          to="/dashboard/user-profile"
          style={{ textDecoration: "none", color: "white" }}
        >
          <Avatar size={50} src={Logo} icon={<UserOutlined />} />
          <h5 style={{ margin: "1px 8px 1px 3px", color: colorBgBase }}>
            {user.name}
          </h5>
          <Badge count={user.role} />
        </Link>
      </div>

      <div style={{ padding: "5px", textAlign: "center" }}>
        {/* Search input box */}
        <Input.Search
          placeholder="Search menu..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: "100%", marginBottom: "8px" }}
        />
      </div>
      <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 260px)" }}>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
        >
          {renderMenuItems(filteredMenuData)}
        </Menu>
      </div>
    </Sider>
  );
}

export default Sidebar;
