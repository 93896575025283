import React, { useState, useEffect } from "react";
import { Table, Select, Button, Space, Alert, DatePicker, Card } from "antd";
import {
  PrinterOutlined,
  EyeOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {
  fetchInvoiceDetailsReport,
  fetchProducts,
} from "../services/ApiServices/apiFunctions";
import dayjs from "dayjs";
import InvoiceView from "./InvoiceView";
import SearchableSelect from "../components/common/SearchableSelect";

const { RangePicker } = DatePicker;

interface ProductsList {
  ID: number;
  BrandName: string;
}

interface DataItem {
  InvoiceNo: string;
  InvoiceType: string;
  Description: string;
  PartyName: string;
  InvoiceDate: string;
  PayMode: string;
  Qty: number;
  Rate: number;
  Total: number;
  BrandName: string;
  ItemDescription: string;
}

const ProductReport: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [productsList, setProductsList] = useState<ProductsList[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedProductID, setselectedProductID] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [InvoiceType, setInvoiceType] = useState<string>("PURCHASE INVOICE");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [error, setError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);

  useEffect(() => {
    fetchTableData(
      pagination.current,
      pagination.pageSize,
      selectedProductID,
      dateRange
    );
    fetchProductsList();
  }, [pagination.current, pagination.pageSize, selectedProductID, dateRange]);

  const fetchProductsList = async () => {
    try {
      const response = await fetchProducts();
      setProductsList(response.responseObject.products);
    } catch (error) {
      setError("An error occurred while fetching products. Please try again.");
    }
  };

  const fetchTableData = async (
    page: number,
    pageSize: number,
    selectedProductID: number,
    dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  ) => {
    setLoading(true);
    setError(null);
    try {
      const fromDate = dateRange
        ? dateRange[0]?.format("YYYY-MM-DD")
        : undefined;
      const toDate = dateRange ? dateRange[1]?.format("YYYY-MM-DD") : undefined;
      setData([]);
      const response = await fetchInvoiceDetailsReport(
        page,
        pageSize,
        selectedProductID,
        fromDate,
        toDate,
        InvoiceType
      );

      setData(response.responseObject.invoiceData.data);
      setGrandTotal(response.responseObject.totalSum);
      setPagination({
        ...pagination,
        total: response.responseObject.invoiceData.total,
      });
    } catch (error) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleDateRangeChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    setDateRange(dates);
    setPagination({ ...pagination, current: 1 });
  };

  const handleView = (record: DataItem) => {
    setSelectedInvoice(record.InvoiceNo);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedInvoice(null);
  };

  const handlePrint = (record: DataItem) => {
    console.log("Printing:", record);
  };

  const handleSelectChange = (value: number) => {
    setselectedProductID(value);
  };

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
    },
    {
      title: "Party Name",
      dataIndex: "PartyName",
      key: "PartyName",
    },
    {
      title: "Invoice Date",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
    },
    {
      title: "Product Name",
      dataIndex: "BrandName",
      key: "BrandName",
    },
    {
      title: "IMEI No",
      dataIndex: "ItemDescription",
      key: "ItemDescription",
    },
    {
      title: "Qty",
      dataIndex: "Qty",
      key: "Qty",
      render: (text: number) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: "Rate",
      dataIndex: "Rate",
      key: "Rate",
      render: (text: number) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      render: (text: number) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: DataItem) => (
        <Space size="middle">
          <Button icon={<EyeOutlined />} onClick={() => handleView(record)} />
          <Button
            icon={<PrinterOutlined />}
            onClick={() => handlePrint(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Card title="Product Report">
      <InvoiceView
        invoiceNumber={selectedInvoice || ""}
        InvoiceType={InvoiceType}
        visible={modalVisible}
        onClose={handleCloseModal}
      />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          style={{
            flex: 1,
            marginRight: "10px",
            marginBottom: "10px",
            width: "100%",
          }}
        >
          <SearchableSelect
            options={productsList}
            placeholder="Select a Product"
            onChange={handleSelectChange}
            idKey="ID"
            nameKey="BrandName"
          />
        </div>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <Select
            aria-labelledby="Invoice Type"
            menuItemSelectedIcon
            defaultValue="PURCHASE INVOICE"
            style={{ width: "100%" }}
            onChange={(value) => setInvoiceType(value)}
            options={[
              { value: "PURCHASE INVOICE", label: "PURCHASE INVOICE" },
              { value: "PURCHASE RETURN", label: "PURCHASE RETURN" },
              { value: "SALE INVOICE", label: "SALE INVOICE" },
              { value: "SALE RETURN", label: "SALE RETURN" },
            ]}
          ></Select>
        </div>
        <div style={{ flex: 1, marginRight: "10px", marginBottom: "10px" }}>
          <RangePicker
            onChange={handleDateRangeChange}
            style={{ width: "100%" }}
            placeholder={["From Date", "To Date"]}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Button
            type="primary"
            onClick={() =>
              fetchTableData(
                pagination.current,
                pagination.pageSize,
                selectedProductID,
                dateRange
              )
            }
            icon={<FilterOutlined />}
          >
            Filter
          </Button>
        </div>
      </div>

      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey="InvoiceNo"
        size="small"
        scroll={{ x: true }} // Enable horizontal scrolling for small screens
        bordered
        summary={(tableData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={5}></Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  <strong>Grand Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="right">
                  <strong>{grandTotal}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </Card>
  );
};

export default ProductReport;
